<script>
import trainingsMixin from '@app/mixins/trainings';
import TrainingPricePlans from '@app/components/TrainingPricePlans.vue';

export default {
  mixins: [trainingsMixin],
  components: { TrainingPricePlans },
  head: {
    title: 'Paramètres',
  },
  forms: [
    {
      price: {
        label: 'Prix (TTC)',
        type: 'number',
        column: 'is-6',
        inputAttrs: {
          min: 0,
          max: 10000,
          step: 0.01,
        },
      },
      discount_price: {
        label: 'Prix promotionnel (TTC)',
        type: 'number',
        column: 'is-6',
        inputAttrs: {
          min: 0,
          max: 10000,
          step: 0.01,
        },
      },
      discount_price_reason: {
        label: 'Raison de la promotion',
        type: 'text',
        column: 'is-12',
        inputAttrs: {
          maxlength: 64,
          hasCounter: false,
        },
        notification: `
          <p>
            Vous pouvez ajouter une raison à votre promotion. Cela renforce son efficacité et sa légitimité.
          </p>
          <p>
            Elle sera affichée en dessous du prix promotionnel, sur votre page de formation.
            <br>
            Exemple : "Promotion de noël" ou "Prix de lancement"
          </p>
        `,
        notificationClass: 'content',
      },
      rate_vat: {
        label: '% de TVA (inclus dans le prix)',
        type: 'number',
        column: 'is-12',
        inputAttrs: {
          min: 0,
          max: 100,
          step: 0.01,
        },
      },
    },
  ],
  data() {
    return {
      isLoading: {
        0: false,
      },
      training: {
        price: '',
        discount_price: '',
        discount_price_reason: '',
        rate_vat: '',
      },
    };
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    hasDiscountPrice() {
      return !Number.isNaN(parseFloat(this.training.discount_price));
    },
  },
  created() {
    this.$options.forms[0].rate_vat.inputAttrs.placeholder = `
      Par défaut : ${this.authStore.default_vat}%
    `.trim();

    this.hydrateTraining();
  },
  methods: {
    hydrateTraining() {
      Object.keys(this.training)
        .forEach((k) => (this.training[k] = this.currentTraining[k]));
    },
    handle(form, fk) {
      const training = {};

      Object.keys(form)
        .forEach((k) => (training[k] = this.training[k]));

      training.price !== undefined && (training.price = training.price || 0);
      training.discount_price !== undefined && (training.discount_price = training.discount_price || null);
      training.rate_vat !== undefined && (training.rate_vat = training.rate_vat || null);

      this.isLoading[fk] = true;
      this.$store.dispatch('trainings/update', { uuid: this.currentTraining.uuid, training, isCurrent: true })
        .then(() => this.$showMessage.success())
        .catch(this.handleStripeError)
        .finally(() => (this.isLoading[fk] = false));
    },
  },
};
</script>

<template>
  <div>
    <AppFeatureWrapper>
      <template #title>
        Tarif de votre formation
      </template>
      <template #content>
        <p>
          Détaillez tous les aspects tarifaires de votre formation comme le prix (en €) ou encore la TVA.
        </p>
        <p>
          Vous avez la possibilité de définir un prix promotionnel. <strong>Mais attention,
          dans ce cas, les codes promo ne s'appliqueront pas lors de l'achat de la formation.</strong>
        </p>
      </template>

      <template #form>
        <form
          v-for="(form, fk) in $options.forms"
          :key="(form, fk)"
          ref="form"
          class="box"
          @submit.prevent="dataIsValid(isLoading[fk]) && handle(form, fk)"
        >
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in form"
              v-show="field.ifModelName == null || training[field.ifModelName] == field.ifModelValue"
              :key="k"
              class="column"
              :class="field.column">
              <BaseField
                v-show="k != 'discount_price_reason' || (k == 'discount_price_reason' && hasDiscountPrice)"
                v-model="training[k]"
                :field="field"
              />
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading[fk]">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </template>
    </AppFeatureWrapper>
    <TrainingPricePlans />
  </div>
</template>
